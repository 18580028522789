import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Card } from 'antd';
import MainLayout from '../../layouts/mainLayout';
import DateFilterButtons from '../../components/datefilterbuttons';
import GroupedDataView from '../../components/groupdataview';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, ArcElement } from 'chart.js';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    ArcElement
);
const LegendWithColors = ({ labels = [], colors = [], values = [] }) => {
    if (!labels.length || !colors.length || !values.length) {
        return <div>No data available</div>;
    }

    // Function to determine the text color based on the alpha value
    const getTextColor = (rgbaColor) => {
        const alpha = parseFloat(rgbaColor.split(',')[3]?.replace(')', '')) || 1;
        return alpha > 0.5 ? '#fff' : '#000';
    };

    return (
        <div className="legend h-full overflow-y-scroll">
            {labels.map((label, index) => {
                const color = colors[index] || 'transparent';
                const textColor = getTextColor(color);

                return (
                    <div key={index} className="flex items-center gap-2 mb-1">
                        <span
                            style={{
                                backgroundColor: color,
                                width: '40px',
                                height: '20px',
                                display: 'inline-block',
                                borderRadius: '1rem',
                                textAlign: "center",
                                color: textColor
                            }}
                        >
                            {values[index] || 'N/A'}
                        </span>
                        <span className='text-center'>{label}</span>
                    </div>
                );
            })}
        </div>
    );
};


const EventsSubscribe = () => {
    const location = useLocation();
    const currentPathName = location.pathname;
    const segments = currentPathName.split('/').filter(segment => segment !== '');
    const appid = segments[1];

    const [successData, setSuccessData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sorter, setSorter] = useState({});
    const [formattedCountries, setFormattedCountries] = useState({});
    const [recordIds] = useState({});
    const [filteredData, setFilteredData] = useState(successData);

    const handleTableChange = (pagination, filters, sorter) => {
        setSorter(sorter);
    };

    useEffect(() => {
        const fetchData = async (appId) => {
            try {
                setIsLoading(true);
                const response = await fetch('https://findbluapp.com/web/eventsubscribe', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'get',
                        appId: appId
                    })
                });
                const responseData = await response.json();
                const newDataWithIds = responseData.data.successfulAttemps.map((record, index) => {
                    if (!record.id) {
                        const newId = index;
                        record.id = newId;
                        recordIds[newId] = record;
                    }
                    return record;
                });
                setSuccessData(newDataWithIds);
                setFilteredData(newDataWithIds);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData(appid);
    }, [appid]);

    useEffect(() => {
        handleDateFilterChange({ days: 0 });
        const fetchAllCountries = async () => {
            const uniqueCountryCodes = new Set(successData.map(item => item.subscribe.country));

            try {
                const response = await fetch('https://findbluapp.com/web/countries', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        countryCodes: Array.from(uniqueCountryCodes)
                    })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const responseData = await response.json();
                setFormattedCountries(responseData.data);
            } catch (error) {
                console.error('Error fetching formatted countries:', error);
            }
        };

        fetchAllCountries();
    }, [successData]);

    const handleDateFilterChange = (range) => {
        if (range === null) {
            setFilteredData(successData);
            return;
        }
        if (range.days === 0) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const filtered = successData.filter((item) => {
                const entryDate = new Date(item.subscribe.entryDate);
                return entryDate >= today;
            });
            setFilteredData(filtered);
            return;
        }

        if (range.days || range.months) {
            const today = new Date();
            let startDate = new Date();

            if (range.days) {
                startDate.setDate(today.getDate() - range.days);
            } else if (range.months) {
                startDate.setMonth(today.getMonth() - range.months);
            }

            const filtered = successData.filter((item) => {
                const entryDate = new Date(item.subscribe.entryDate);
                return entryDate >= startDate;
            });
            setFilteredData(filtered);
        } else {
            const filtered = successData.filter((item) => {
                const entryDate = new Date(item.subscribe.entryDate);
                return (entryDate >= range.startDate && entryDate <= range.endDate);
            });
            setFilteredData(filtered);
        }
    };

    const groupedData = {
        action: {},
        placement: {},
        country: {}
    };

    filteredData.forEach((item) => {
        const actionName = item.subscribe.actionName;
        const placementId = item.subscribe.placementId;

        if (!groupedData.action[actionName]) {
            groupedData.action[actionName] = 0;
        }
        groupedData.action[actionName]++;

        if (!groupedData.placement[placementId]) {
            groupedData.placement[placementId] = 0;
        }
        groupedData.placement[placementId]++;

        const countryCode = item.subscribe.country;
        const countryName = formattedCountries[countryCode]?.name || countryCode;
        const countryIconUrl = formattedCountries[countryCode]?.iconUrl || '';

        if (!groupedData.country[countryName]) {
            groupedData.country[countryName] = {
                name: countryName,
                iconUrl: countryIconUrl,
                count: 0
            };
        }
        groupedData.country[countryName].count++;
    });

    const sortedCountryEntries = Object.entries(groupedData.country).sort(([, a], [, b]) => b.count - a.count);
    groupedData.country = Object.fromEntries(sortedCountryEntries);
    const sortedPlacementEntries = Object.entries(groupedData.placement).sort(([, a], [, b]) => b - a);
    groupedData.placement = Object.fromEntries(sortedPlacementEntries);
    const sortedActionEntries = Object.entries(groupedData.action).sort(([, a], [, b]) => b - a);
    groupedData.action = Object.fromEntries(sortedActionEntries);

    const chartData = useMemo(() => {
        return {
            labels: Object.keys(groupedData.country),
            datasets: [
                {
                    label: 'Country Count',
                    data: Object.values(groupedData.country).map(item => item.count),
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                }
            ]
        };
    }, [groupedData.country]);

    const pieData = useMemo(() => {
        return {
            labels: Object.keys(groupedData.action),
            datasets: [{
                label: 'Action Count',
                data: Object.values(groupedData.action),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(199, 199, 199, 0.2)',
                    'rgba(83, 102, 255, 0.2)',
                    'rgba(255, 205, 86, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(103, 159, 64, 0.2)',
                    'rgba(173, 102, 255, 0.2)',
                    'rgba(255, 85, 102, 0.2)',
                    'rgba(255, 194, 64, 0.2)',
                    'rgba(255, 139, 132, 0.2)',
                    'rgba(83, 192, 192, 0.2)',
                    'rgba(153, 0, 255, 0.2)',
                    'rgba(255, 50, 86, 0.2)',
                    'rgba(75, 102, 86, 0.2)',
                    'rgba(183, 202, 132, 0.2)',
                    'rgba(92, 192, 192, 0.2)',
                    'rgba(153, 102, 105, 0.2)',
                    'rgba(240, 159, 64, 0.2)',
                    'rgba(255, 193, 132, 0.2)',
                    'rgba(145, 206, 86, 0.2)',
                    'rgba(255, 62, 64, 0.2)',
                    'rgba(102, 102, 235, 0.2)',
                    'rgba(75, 255, 192, 0.2)',
                    'rgba(255, 165, 0, 0.2)',
                    'rgba(100, 149, 237, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(199, 199, 199, 1)',
                    'rgba(83, 102, 255, 1)',
                    'rgba(255, 205, 86, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(103, 159, 64, 1)',
                    'rgba(173, 102, 255, 1)',
                    'rgba(255, 85, 102, 1)',
                    'rgba(255, 194, 64, 1)',
                    'rgba(255, 139, 132, 1)',
                    'rgba(83, 192, 192, 1)',
                    'rgba(153, 0, 255, 1)',
                    'rgba(255, 50, 86, 1)',
                    'rgba(75, 102, 86, 1)',
                    'rgba(183, 202, 132, 1)',
                    'rgba(92, 192, 192, 1)',
                    'rgba(153, 102, 105, 1)',
                    'rgba(240, 159, 64, 1)',
                    'rgba(255, 193, 132, 1)',
                    'rgba(145, 206, 86, 1)',
                    'rgba(255, 62, 64, 1)',
                    'rgba(102, 102, 235, 1)',
                    'rgba(75, 255, 192, 1)',
                    'rgba(255, 165, 0, 1)',
                    'rgba(100, 149, 237, 1)'
                ],
                borderWidth: 1,
            }]
        };
    }, [groupedData.action]);

    const placementData = {
        labels: Object.keys(groupedData.placement),
        datasets: [{
            label: 'Placement',
            data: Object.values(groupedData.placement),
            backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(199, 199, 199, 1)',
                'rgba(83, 102, 255, 1)',
                'rgba(255, 205, 86, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(103, 159, 64, 1)',
                'rgba(173, 102, 255, 1)',
                'rgba(255, 85, 102, 1)',
                'rgba(255, 194, 64, 1)',
                'rgba(255, 139, 132, 1)',
                'rgba(83, 192, 192, 1)',
                'rgba(153, 0, 255, 1)',
                'rgba(255, 50, 86, 1)',
                'rgba(75, 102, 86, 1)',
                'rgba(183, 202, 132, 1)',
                'rgba(92, 192, 192, 1)',
                'rgba(153, 102, 105, 1)',
                'rgba(240, 159, 64, 1)',
                'rgba(255, 193, 132, 1)',
                'rgba(145, 206, 86, 1)',
                'rgba(255, 62, 64, 1)',
                'rgba(102, 102, 235, 1)',
                'rgba(75, 255, 192, 1)',
                'rgba(255, 165, 0, 1)',
                'rgba(100, 149, 237, 1)'
            ],
            hoverOffset: 4
        }]
    }


    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: ({ dataset: { label = '' }, parsed: { y = 0 } }) => `${label}: ${y}`
                }
            }
        },
        scales: {
            x: {
                beginAtZero: true,
                reverse: false,
            },
            y: {
                beginAtZero: true,
                reverse: false
            }
        },
        indexAxis: 'y'
    };

    const pieOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true, // Legend'ı göster
                position: 'top', // Legend'ın konumu
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.label || '';
                        let value = context.raw || 0;
                        if (label) {
                            label += ': ';
                        }
                        if (value) {
                            label += value;
                        }
                        return label;
                    }
                }
            }
        }
    };



    const columns = [
        {
            title: 'Action Name',
            dataIndex: ['subscribe', 'actionName'],
            key: 'actionName',
        },
        {
            title: 'Product Code',
            dataIndex: ['subscribe', 'productCode'],
            key: 'productCode',
        },
        {
            title: 'Placement ID',
            dataIndex: ['subscribe', 'placementId'],
            key: 'placementId',
        },
        {
            title: 'Country',
            dataIndex: ['subscribe', 'country'],
            key: 'country',
            render: (text, record) => {
                const countryCode = record.subscribe.country;
                const formattedCountry = formattedCountries[countryCode];
                if (formattedCountry) {
                    return (
                        <div className='flex items-center justify-start gap-2'>
                            <img width="20" className='h-4' src={formattedCountry.iconUrl} alt={formattedCountry.name} loading='lazy' />
                            <span>{formattedCountry.name}</span>
                        </div>
                    );
                } else {
                    return <div>Loading country data...</div>;
                }
            },
        },
        {
            title: 'Date',
            dataIndex: ['subscribe', 'entryDate'],
            key: 'entryDate',
            sorter: (a, b) => new Date(a.subscribe.entryDate) - new Date(b.subscribe.entryDate),
        },
    ];

    return (
        <MainLayout>
            <div className='flex justify-between'>
                <DateFilterButtons onDateFilterChange={handleDateFilterChange} />
                <div className='p-2 px-4 bg-white rounded'>
                    <p className='m-0 p-0'>Total subscribe: <b>{filteredData.length}</b></p>
                </div>
            </div>

            <div className='flex gap-4'>
                <div className='w-3/5'>
                    <Card title="Country Distribution" className='h-full'>
                        <div className='relative h-64'>
                            <Bar
                                data={chartData}
                                options={chartOptions}
                                style={{ width: '100%', height: '100%' }}
                            />
                        </div>
                    </Card>
                </div>
                <div className='w-2/5 flex flex-col'>
                    <Card title="Action Distribution" className='flex-grow'>
                        <div className='relative flex justify-between h-64'>
                            <Pie
                                data={pieData}
                                options={pieOptions}
                                style={{ width: '50%', height: '50%' }}
                            />
                            <div>
                                <LegendWithColors
                                    labels={pieData.labels}
                                    colors={pieData.datasets[0].backgroundColor}
                                    values={Object.values(groupedData.action)}
                                    />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div className='w-2/5 flex flex-col'>
                <Card title="Placement Distribution" className='flex-grow'>
                    <div className='relative flex justify-between h-64'>
                        <Doughnut
                            data={placementData}
                            style={{ width: '30%', height: '30%' }}
                        ></Doughnut>
                        <div>
                            <LegendWithColors
                                labels={placementData.labels}
                                colors={placementData.datasets[0].backgroundColor}
                                values={Object.values(groupedData.placement)}
                            />
                        </div>
                    </div>
                </Card>
            </div>

            <div className=''>
                {/* <GroupedDataView title="Action" data={groupedData.action} /> */}
                <GroupedDataView title="Placement" data={groupedData.placement} />
                <GroupedDataView title="Country" data={groupedData.country} />
            </div>
            <div>
                {isLoading ? (
                    <h1>Loading...</h1>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={filteredData}
                        pagination={{ pageSize: 8 }}
                        loading={isLoading}
                        rowKey={(record) => record.id}
                        onChange={handleTableChange}
                        sorter={sorter}
                    />
                )}
            </div>
        </MainLayout>
    );
};

export default EventsSubscribe;
