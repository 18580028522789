import React, { useState, useEffect } from "react";
import MainLayout from "../../../../layouts/mainLayout.js";
import { Form, Input, InputNumber, Select, Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

const SkinCareProductsEdit = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [initialValues, setInitialValues] = useState(null);
    const [ingredients, setIngredients] = useState([]);

    // Fetch ingredients on component mount
    useEffect(() => {
        fetchIngredients();
    }, []);

    const fetchIngredients = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://findbluapp.com/skincare_ingredients', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'view',
                    data: []
                }),
            });

            if (!response.ok) {
                throw new Error('Error fetching ingredients');
            }

            const data = await response.json();
            const updatedIngredients = data['data'].map((item) => ({
                id: item.id,
                name: item.title
            }));

            setIngredients(updatedIngredients);
        } catch (error) {
            message.error('Error loading ingredients');
            console.error(error);
        }
        setLoading(false);
    };

    // Upload file to server
    const uploadToServer = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        
        try {
            const response = await fetch("https://findbluapp.com/skincare_upload", {
                method: "POST",
                body: formData,
            });

            const data = await response.json();
            if (response.ok) {
                return data; // Return full response object with filePath and fileName
            } else {
                throw new Error(data.message || "File upload failed");
            }
        } catch (error) {
            console.error("Upload error:", error);
            message.error("File upload failed");
            return null;
        }
    };

    // Handle file upload
    const handleUpload = async (info) => {
        const { status } = info.file;
        let newFileList = [...info.fileList];

        if (status === 'uploading') {
            setFileList(newFileList);
        } else if (status === 'done' || status === 'error') {
            try {
                // Attempt to upload file to server
                const uploadResponse = await uploadToServer(info.file.originFileObj);
                
                if (uploadResponse) {
                    // Update fileList with server response
                    newFileList = newFileList.map(file => ({
                        ...file,
                        status: 'done',
                        url: uploadResponse.filePath,
                        fileName: uploadResponse.fileName
                    }));
                    
                    setFileList(newFileList);
                    message.success("File uploaded successfully");
                } else {
                    // Remove file if upload fails
                    newFileList = newFileList.filter(file => file.uid !== info.file.uid);
                    setFileList(newFileList);
                    message.error("File upload failed");
                }
            } catch (error) {
                console.error("Upload handling error:", error);
                newFileList = newFileList.filter(file => file.uid !== info.file.uid);
                setFileList(newFileList);
                message.error("File upload failed");
            }
        }
    };

    // Fetch product data
    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const response = await fetch(`https://findbluapp.com/skincare_products?id=${id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        method: 'POST',
                        action: 'view',
                        data: {
                            id: id
                        }
                    })
                });
                if (response.ok) {
                    const data = await response.json();
                    const productData = data['data'][0];

                    // Parse ingredients from JSON string
                    const parsedIngredients = JSON.parse(productData.ingredients).map(ingredient => ingredient.id);

                    // Set form values
                    form.setFieldsValue({
                        productName: productData.product_name,
                        healthScore: productData.health_score,
                        ingredients: parsedIngredients,
                    });

                    // If the product has an existing image
                    if (productData.product_image) {
                        setFileList([
                            {
                                uid: '-1',
                                name: 'current-image.jpg',
                                status: 'done',
                                url: productData.product_image,
                            },
                        ]);
                    }
                    
                    setInitialValues(productData);
                }
            } catch (error) {
                message.error('Error loading product data');
            }
        };

        if (id) {
            fetchProductData();
        }
    }, [id, form]);

    // Upload props configuration
    const uploadProps = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        listType: 'picture-card',
        accept: '.jpg,.jpeg,.png',
        beforeUpload: (file) => {
            const isImage = file.type.startsWith('image/');
            if (!isImage) {
                message.error(`${file.name} is not an image file`);
            }
            return isImage || Upload.LIST_IGNORE;
        },
        onChange: handleUpload
    };

    // Form submission
    const onFinish = async (values) => {
        setLoading(true);
        try {
            const imageUrl = fileList[0]?.url || "";
            const formData = {
                action: 'edit',
                data: {
                    id: initialValues.id,
                    product_name: values.productName,
                    product_image: imageUrl,
                    health_score: values.healthScore,
                    ingredients: values.ingredients.map((item) => {
                        const ingredient = ingredients.find((option) => option.id === item);
                        return { id: ingredient.id, name: ingredient.name };
                    }),
                }
            };

            const response = await fetch('https://findbluapp.com/skincare_products', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                message.success('Product updated successfully');
                navigate('/skincare/products');
            } else {
                const errorData = await response.json();
                message.error(errorData.message || 'Product update failed');
            }
        } catch (error) {
            console.error("Product update error:", error);
            message.error('An error occurred while updating the product');
        }
        setLoading(false);
    };

    return (
        <MainLayout>
            <div>
                <h1 className="text-2xl font-bold mb-6">
                    {id ? 'Edit Product' : 'Create New Product'}
                </h1>
                <div className="bg-white rounded-lg shadow p-6">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="productName"
                            label="Product Name"
                            rules={[{ required: true, message: 'Please enter product name' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="productImage"
                            label="Product Image"
                        >
                            <Upload
                                {...uploadProps}
                                fileList={fileList}
                            >
                                {fileList.length < 1 && (
                                    <div>
                                        <UploadOutlined />
                                        <div style={{ marginTop: 8 }}>Select Image</div>
                                    </div>
                                )}
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="healthScore"
                            label="Health Score"
                            rules={[{ required: true, message: 'Please enter health score' }]}
                        >
                            <InputNumber min={0} max={100} style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item
                            name="ingredients"
                            label="Ingredients"
                            rules={[{ required: true, message: 'Please select at least one ingredient' }]}
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="Select ingredients"
                                optionFilterProp="children"
                                options={ingredients.map(ingredient => ({
                                    value: ingredient.id, 
                                    label: ingredient.name
                                }))}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                className="bg-gray-700 hover:bg-gray-800"
                            >
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </MainLayout>
    );
};

export default SkinCareProductsEdit;