import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../../layouts/mainLayout.js";
import { Form, Input, Button, Select, Card, Tag, Space, message } from "antd";
import { 
    ArrowLeftOutlined, 
    SaveOutlined, 
    WarningOutlined,
    AlertOutlined,
    MedicineBoxOutlined,
    LinkOutlined,
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const SkinCareIngredientsNew = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [healthEffects, setHealthEffects] = useState([]);
    const [form] = Form.useForm();

    const healthEffectOptions = [
        { label: 'Irritation', value: 'irritation', icon: <WarningOutlined /> },
        { label: 'Allergy', value: 'allergy', icon: <AlertOutlined /> },
        { label: 'Sensitivity', value: 'sensitivity', icon: <MedicineBoxOutlined /> },
        { label: 'Dryness', value: 'dryness', icon: <WarningOutlined /> },
        { label: 'Acne', value: 'acne', icon: <AlertOutlined /> },
        { label: 'Unsafe in High Amounts', value: 'overdose', icon: <AlertOutlined /> },
    ];

    const onFinish = async (values) => {
        setLoading(true);
        try {            
            const response = await fetch('https://findbluapp.com/skincare_ingredients', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Content type should be JSON
                },
                body: JSON.stringify({
                    action: 'insert', 
                    data: {
                        title: values.title,
                        riskLevel: values.riskLevel,
                        healthEffects: healthEffects, 
                        details: values.details,
                        sources: values.sources, 
                    },
                }), 
            });
    
            if (response.ok) {
                const result = await response.json();
                if (result.success) {
                    message.success('Ingredient added successfully');
                    navigate('/skincare/ingredients'); // Navigate on success
                } else {
                    message.error(result.error || 'Error adding ingredient');
                }
            } else {
                message.error('Failed to add ingredient');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Error adding ingredient');
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <MainLayout>
            <div>
                <div className="mb-4 flex items-center gap-4">
                    <Button 
                        icon={<ArrowLeftOutlined />} 
                        onClick={() => navigate('/skincare/ingredients')}
                    >
                        Back
                    </Button>
                    <h1 className="text-2xl font-bold">New Ingredient</h1>
                </div>

                <Card className="max-w-2xl">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        disabled={loading}
                    >
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[{ required: true, message: 'Please input ingredient title!' }]}
                        >
                            <Input placeholder="Enter ingredient title" />
                        </Form.Item>

                        <Form.Item
                            name="riskLevel"
                            label="Risk Level"
                            rules={[{ required: true, message: 'Please select risk level!' }]}
                        >
                            <Select placeholder="Select risk level">
                                <Option value="high">
                                    <Tag color="red">High Risk</Tag>
                                </Option>
                                <Option value="low">
                                    <Tag color="orange">Low Risk</Tag>
                                </Option>
                                <Option value="norisk">
                                    <Tag color="green">No Risk</Tag>
                                </Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Health Effects"
                            required
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select health effects"
                                value={healthEffects}
                                onChange={setHealthEffects}
                                style={{ width: '100%' }}
                            >
                                {healthEffectOptions.map(option => (
                                    <Option key={option.value} value={option.value}>
                                        <Space>
                                            {option.icon}
                                            {option.label}
                                        </Space>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="details"
                            label="Details"
                        >
                            <TextArea 
                                rows={4} 
                                placeholder="Enter detailed description of the ingredient"
                            />
                        </Form.Item>

                        <Form.List
                            name="sources"
                            initialValue={['']}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            key={field.key}
                                            style={{ marginBottom: 8 }}
                                        >
                                            <Form.Item
                                                {...field}
                                                label={index === 0 ? "Sources" : ""}
                                                required={true}
                                                rules={[
                                                    { type: 'url', message: 'Please enter a valid URL' }
                                                ]}
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Input 
                                                    placeholder="Enter source URL" 
                                                    prefix={<LinkOutlined />}
                                                    suffix={
                                                        fields.length > 1 && (
                                                            <MinusCircleOutlined 
                                                                onClick={() => remove(field.name)}
                                                                className="text-red-500 cursor-pointer"
                                                            />
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            icon={<PlusOutlined />}
                                            className="w-full"
                                        >
                                            Add Source
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                icon={<SaveOutlined />}
                                loading={loading}
                                className="bg-blue-600 hover:bg-blue-700"
                            >
                                Save Ingredient
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </MainLayout>
    );
};

export default SkinCareIngredientsNew;
