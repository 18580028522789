import React, { useState, useEffect } from "react";
import MainLayout from "../../../../layouts/mainLayout.js";
import { Table, Button, Popconfirm, message, Input } from "antd";
import { EditOutlined, DeleteOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const SkinCareProductsView = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://findbluapp.com/skincare_products', {
                method: 'POST', // POST method
                headers: {
                    'Content-Type': 'application/json', // Content type should be JSON
                },
                body: JSON.stringify({
                    action: 'view',
                    data: [] 
                })
            });
            
            const data = await response.json(); // Parse the JSON response
            console.log(data);
            
            if (data.error) {
                throw new Error(data.error); // Handle errors if they occur
            }
    
            setProducts(data['data']); // Update the state with the fetched data
        } catch (error) {
            message.error('Error loading products: ' + error.message); // Show error message if something goes wrong
        }
        setLoading(false); // Stop loading
    };
    

    const handleDelete = async (id) => {
        try {
            const response = await fetch('https://findbluapp.com/skincare_products', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'delete',
                    data: { id: id },
                }),
            });
            const result = await response.json();
            if (result.error) {
                throw new Error(result.error);
            }
            message.success(result.success || 'Product deleted successfully');
            fetchProducts();
        } catch (error) {
            message.error('Error deleting product: ' + error.message);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => b.id - a.id, // Descending order sort
            defaultSortOrder: 'descend',
        },
        {
            title: 'Image',
            dataIndex: 'product_image',
            key: 'product_image',
            render: (product_image) => (
                <img src={product_image} alt="product" className="w-16 h-16 object-cover rounded" />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'product_name',
            key: 'product_name',
            filteredValue: [searchText],
            onFilter: (value, record) =>
                record.product_name.toLowerCase().includes(value.toLowerCase()),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className="space-x-2">
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => navigate(`/skincare/products/edit/${record.id}`)}
                    >
                        Edit
                    </Button>
                    <Popconfirm
                        title="Delete Confirmation"
                        description="Are you sure you want to delete this product?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger icon={<DeleteOutlined />}>
                            Delete
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <MainLayout>
            <div>
                <div className="mb-4 flex flex-col sm:flex-row justify-between items-center gap-4">
                    <div className="flex items-center gap-4 w-full sm:w-auto">
                        <h1 className="text-2xl font-bold">Product List</h1>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
                        <Input
                            placeholder="Search by name..."
                            prefix={<SearchOutlined />}
                            onChange={(e) => setSearchText(e.target.value)}
                            className="w-full sm:w-64"
                            allowClear
                        />
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => navigate('/skincare/products/new')}
                            className="bg-gray-700 hover:bg-gray-800 w-full sm:w-auto"
                        >
                            New Ingredient
                        </Button>
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={products}
                    rowKey="id"
                    loading={loading}
                    className="bg-white rounded-lg shadow"
                />
            </div>
        </MainLayout>
    );
};

export default SkinCareProductsView;
