import React, { useState, useEffect } from "react";
import MainLayout from "../../../../layouts/mainLayout.js";
import { Table, Button, Popconfirm, message, Input, Tag } from "antd";
import { EditOutlined, DeleteOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const SkinCareIngredientsView = () => {
    const [ingredients, setIngredients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchIngredients();
    }, []);

    const fetchIngredients = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://findbluapp.com/skincare_ingredients', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'view',
                    data: []
                }),
            });

            if (!response.ok) {
                throw new Error('Error fetching ingredients');
            }

            const data = await response.json();
            console.log(data);

            setIngredients(data['data']);
        } catch (error) {
            message.error('Error loading ingredients');
        }
        setLoading(false);
    };


    const handleDelete = async (id) => {
        try {
            const response = await fetch('https://findbluapp.com/skincare_ingredients', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'delete',
                    data: { id: id },
                }),
            });

            if (!response.ok) {
                throw new Error('Error deleting ingredient');
            }

            const result = await response.json();
            if (result.success) {
                message.success('Ingredient deleted successfully');
                fetchIngredients();
            } else {
                message.error(result.error || 'Failed to delete ingredient');
            }
        } catch (error) {
            message.error('Error deleting ingredient');
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => b.id - a.id,
            defaultSortOrder: 'descend'
        },
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
            filteredValue: [searchText],
            onFilter: (value, record) => {
                return record.title.toLowerCase().includes(value.toLowerCase());
            }
        },
        {
            title: 'Description',
            dataIndex: 'details',
            key: 'details',
            render: (details) => (
                <div
                    className="max-w-[200px] md:max-w-[300px] lg:max-w-[400px] truncate overflow-hidden text-ellipsis whitespace-nowrap"
                    title={details} // Show full text on hover
                >
                    {details}
                </div>
            ),
        },
        {
            title: 'Risk',
            dataIndex: 'risk_level',
            key: 'risk_level',
            render: (risk) => {
                let color = '';
                let text = '';

                switch (risk) {
                    case 'HIGH':
                        color = 'red';
                        text = 'High Risk';
                        break;
                    case 'LOW':
                        color = 'orange';
                        text = 'Low Risk';
                        break;
                    case 'NORISK':
                        color = 'green';
                        text = 'No Risk';
                        break;
                    default:
                        return null; // Eğer risk değeri tanımlı değilse hiçbir şey döndürülmez.
                }

                return <Tag color={color}>{text}</Tag>;
            },
        },

        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className="space-x-2">
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => navigate(`/skincare/ingredients/edit/${record.id}`)}
                    >
                        Edit
                    </Button>
                    <Popconfirm
                        title="Delete Confirmation"
                        description="Are you sure you want to delete this ingredient?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger icon={<DeleteOutlined />}>
                            Delete
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <MainLayout>
            <div>
                <div className="mb-4 flex flex-col sm:flex-row justify-between items-center gap-4">
                    <div className="flex items-center gap-4 w-full sm:w-auto">
                        <h1 className="text-2xl font-bold">Ingredient List</h1>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
                        <Input
                            placeholder="Search by name..."
                            prefix={<SearchOutlined />}
                            onChange={(e) => setSearchText(e.target.value)}
                            className="w-full sm:w-64"
                            allowClear
                        />
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => navigate('/skincare/ingredients/new')}
                            className="bg-gray-700 hover:bg-gray-800 w-full sm:w-auto"
                        >
                            New Ingredient
                        </Button>
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={ingredients}
                    rowKey="id"
                    loading={loading}
                    className="bg-white rounded-lg shadow"
                />
            </div>
        </MainLayout>
    );
};

export default SkinCareIngredientsView;
