import React, { useState, useEffect } from 'react';
import MainLayout from '../../layouts/mainLayout';
import styles from './styles.module.css';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { IoIosSearch } from 'react-icons/io';

const ImageUpload = () => {
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // Kategorileri ykleme
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://findbluapp.com/web/imageupload', {
                    method: 'POST',
                    headers: {
                        'Authorization': process.env.REACT_APP_DRAW_CATEGORIES_KEY // API anahtarınızı burada belirtin
                    },
                    body: JSON.stringify({ action: "categories" }) // Verinin doğruluğu önemli
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                console.log(data);
                
                setCategories(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    // Arama fonksiyonu
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // Filtrelenmiş uygulama listesini oluştur
    const filteredData = categories && categories.filter(app => app.catName.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <MainLayout>
            <div>
                <h1>Kategoriler</h1>
                <div className={styles.appsHeading}>
                    <div className={styles.searchDiv}><IoIosSearch /><input type="search" placeholder='Search' onChange={handleSearch} /></div>

                    <Link to={`/apps/2/imageupload/new`} className={styles.newAppBtn}><FiPlus /> New Categories</Link>
                </div>
            </div>
            <div className={styles.categoryWrapper}>
                {filteredData.map((category) => (
                    <Link to={`/apps/2/imageupload/${category.catId}`} key={category.catId} className={styles.categoryBox}>
                        <img src={category.catImg} alt="" />
                        {category.catName}
                    </Link>
                ))}
            </div>
        </MainLayout>
    );
};

export default ImageUpload;
