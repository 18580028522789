import { BiArrowFromRight, BiArrowFromLeft, BiChevronUp, BiChevronDown, BiSubdirectoryRight } from "react-icons/bi";
import { useContext, createContext, useState } from "react";
import { Link } from "react-router-dom"; // Link bileşenini ekledik
import { useSelector } from 'react-redux';

const SidebarContext = createContext();

export default function Sidebar({ children, userName, userEmail }) {
    const email = useSelector(state => state.email);
    const [expanded, setExpanded] = useState(false);
    const [isPinned, setIsPinned] = useState(false); // Sabitleme durumu için

    return (
        <>
            <div
                className={`sidebar-overlay ${expanded ? 'visible' : ''}`}
                onClick={() => setExpanded(false)}
            />

            <aside className={`
                ${isPinned ? 'relative' : 'absolute'} 
                h-screen
                ${expanded || isPinned ? 'w-64' : 'w-16'} 
                transition-all duration-300 ease-in-out
                bg-white
                md:h-full
            `}>
                <nav className="h-full flex flex-col bg-white border-r border-gray-200 shadow-sm" onMouseEnter={() => !isPinned && setExpanded(true)}
                    onMouseLeave={() => !isPinned && setExpanded(false)}>
                    <div className="p-4 pb-2 flex justify-between items-center border-b border-gray-100">
                        <img
                            src="/logo.jpg"
                            onClick={() => setIsPinned(!isPinned)}
                            className={`
                                    cursor-pointer
                                    transition-transform duration-300 ease-in-out
                                    transform-gpu hover:scale-105
                                    rounded-[8px]
                                    ${expanded || isPinned
                                    ? "w-12 mx-auto hover:shadow-lg active:scale-95"
                                    : "w-8 hover:shadow-md active:scale-90"
                                }
    `}
                            alt=""
                        />

                    </div>

                    <div
                        className="relative flex-1"
                    >
                        <SidebarContext.Provider value={{ expanded: expanded || isPinned }}>
                            <ul className="flex-1 px-3">{children}</ul>
                        </SidebarContext.Provider>
                    </div>
                </nav>
            </aside>
        </>
    );
}

export function SidebarItem({ icon, text, active, alert, route, children }) {
    const { expanded } = useContext(SidebarContext);
    const [open, setOpen] = useState(false);

    return (
        <li className="relative">
            {children ? (
                <>
                    <div
                        onClick={() => children && setOpen(!open)}
                        className={`
                        relative flex justify-start items-center py-2 px-3 my-1
                        font-medium rounded-md cursor-pointer
                        transition-all group ease-in
                        ${open ? "bg-gray-100 text-gray-700" : "hover:bg-gray-50 text-gray-600"}
                        md:whitespace-nowrap
                    `}
                    >
                        {icon}
                        <span className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>{text}</span>
                        {/* ... diğer kodlar (alert, tooltip vb.) */}

                        {children && (
                            <span className="ml-auto">
                                {/* Submenu açma/kapama butonu */}
                                {open ? <BiChevronUp /> : <BiChevronDown />}
                            </span>
                        )}
                    </div>

                    {children && open && (
                        <ul className={`
                            ml-${expanded ? '12' : '6'} 
                            ${open ? 'opacity-100 bg-gray-50 text-gray-700' : 'opacity-0 hover:bg-gray-50 text-gray-600'}
                            md:absolute md:left-full md:top-0 md:ml-1 md:pl-2 md:min-w-[200px] 
                            md:rounded-md md:shadow-lg md:border md:border-gray-100 md:bg-white
                        `}>
                            {children}
                        </ul>
                    )}
                </>
            ) : (
                <Link to={route} className={`
                    relative flex items-center py-2 px-3 my-1
                    font-medium rounded-md cursor-pointer
                    transition-colors group
                    ${active ? "bg-gray-100 text-gray-700" : "hover:bg-gray-50 text-gray-600"}
                    md:whitespace-nowrap
                `}>
                    {icon}
                    <span className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>{text}</span>
                    {alert && (
                        <div className={`absolute right-2 w-2 h-2 rounded bg-blue-400 ${expanded ? "" : "top-2"}`} />
                    )}

                    {!expanded && (
                        <div className={`
                        absolute left-full rounded-md px-2 py-1 ml-6
                        bg-gray-100 text-gray-700 text-sm
                        invisible opacity-20 -translate-x-3 transition-all
                        group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
                    `}>
                            {text}
                        </div>
                    )}
                </Link>
            )}

        </li>
    );
}

export function NestedSidebarItem({ text, route }) {
    return (
        <li>
            <Link to={route} className={`
                flex items-center py-2 px-6 font-medium rounded-md 
                hover:bg-gray-50 text-gray-600
                transition-colors duration-200
            `}>
                <BiSubdirectoryRight className="mr-2" /> {text}
            </Link>
        </li>
    );
}
