import React from "react";
import SidebarContainer from "../components/sidebar";
import Footer from "../components/footer";
import Header from "../components/header";

const MainLayout = ({ children }) => {
    return (
        <div className="App mainLayout relative min-h-screen">
            <div className="flex">
                <div className="z-20">
                    <SidebarContainer />
                </div>
                <main className="flex-grow min-h-screen w-full bg-gray-100 p-3 pl-20">
                    <Header />
                    <div className="p-4">
                        {children}
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    );
};

export default MainLayout;