import React from 'react';
import './styles.module.css';
import { useLocation } from 'react-router-dom';
import Sidebar, { SidebarItem, NestedSidebarItem } from './sidebarComponent';
import { BiExtension, BiDevices, BiPurchaseTag, BiChart, BiCool, BiData, BiUpload, BiDesktop, BiCustomize, BiGridAlt } from "react-icons/bi";

function SidebarContainer() {
    const location = useLocation();
    const currentPathName = location.pathname;
    const showOtherTabs = currentPathName.startsWith('/apps/');
    const showDrawData = currentPathName.startsWith('/apps/2');
    const segments = currentPathName.split('/').filter(segment => segment !== ''); // URL'yi '/' karakterlerine göre ayırıp boş olmayanları alıyoruz
    const appid = segments[1];
    // const logo2url = process.env.LOGO2URL;
    return (
        <Sidebar>
            <SidebarItem icon={<BiChart size={20} />} text="Dashboard" alert route="/" />
            <SidebarItem icon={<BiExtension size={20} />} text="Apps" route="/apps" />
            <SidebarItem icon={<BiCustomize size={20} />} text="SkinCare Products" route="/skincare/products" />
            <SidebarItem icon={<BiGridAlt size={20} />} text="SkinCare Ingredients" route="/skincare/ingredients" />
            {showOtherTabs && (
                <>
                    <SidebarItem icon={<BiDesktop size={20} />} text="App Detail" route={`/apps/${appid}`} />
                    <SidebarItem icon={<BiDevices size={20} />} text="Demodevice" route={`/apps/${appid}/demodevice`} />
                    <SidebarItem icon={<BiPurchaseTag size={20} />} text="Actions" route={`/apps/${appid}/paywallactions`} />
                    <SidebarItem icon={<BiCool size={20} />} text="Events" route={`/apps/${appid}/events`}>
                        <NestedSidebarItem text="Subscribe" route={`/apps/${appid}/events/subscribe`} />
                        <NestedSidebarItem text="Paywall Open" route={`/apps/${appid}/events/paywallopen`} />
                        <NestedSidebarItem text="First Install" route={`/apps/${appid}/events/firstinstall`} />
                    </SidebarItem>
                    {showDrawData && (
                        <>
                            <SidebarItem icon={<BiData size={20} />} text="View Data" route="/apps/2/viewdata" />
                            <SidebarItem icon={<BiUpload size={20} />} text="Image Upload" route="/apps/2/imageupload" />
                        </>
                    )}
                </>
            )}

        </Sidebar>
    );
}

export default SidebarContainer;