import React, { useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import { Form, Input, Button, message, Upload, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

export default function ImageUploadCreateNewCategory() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false); // Loader durumu

    const validateFile = (file) => {
        const isPng = file.type === 'image/png';
        if (!isPng) {
            message.error('Only PNG files are allowed.');
            return Upload.LIST_IGNORE;
        }

        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                if (img.width !== img.height || img.width !== 512 || img.height !== 512) {
                    message.error('Image must be a 1:1 square and 512x512 in dimensions.');
                    reject();
                } else {
                    resolve();
                }
            };
        });
    };

    const onFinish = async (values) => {
        console.log('Form Values:', values.categoryName);
        setLoading(true); // Loader'ı başlat

        try {
            const response = await fetch('https://findbluapp.com/web/imageupload', {
                method: 'POST',
                headers: {
                    'Authorization': process.env.REACT_APP_DRAW_CATEGORIES_KEY,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: "insertcategory",
                    categoryName: values.categoryName.toLowerCase()
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const fetchedData = await response.json();
            console.log(fetchedData);
            message.success('Category created successfully!');
        } catch (error) {
            console.error('Error fetching data:', error);
            message.error('Failed to create category.');
        } finally {
            setLoading(false); // İşlem tamamlandıktan sonra loader'ı durdur
        }
    };

    return (
        <MainLayout>
            <Spin spinning={loading} tip="Processing..."> {/* Loader burada */}
                <div>
                    <h1>Create New Category</h1>
                    <Form form={form} onFinish={onFinish}>
                        <Form.Item 
                            name="categoryName" 
                            label="Category Name" 
                            rules={[{ required: true, message: 'Please enter a category name' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item 
                            name="imageUploader" 
                            label="Upload Image" 
                            valuePropName="fileList"
                            getValueFromEvent={(e) => e.fileList}
                            rules={[{ required: true, message: 'Please upload an image' }]}
                        >
                            <Upload 
                                accept=".png" 
                                listType="picture-card" 
                                beforeUpload={validateFile}
                                maxCount={1} // Sadece bir dosya yüklemeye izin verir
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                            </Upload>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={loading}>
                                Create Category
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </MainLayout>
    );
}
