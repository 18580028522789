import React, { useState, useEffect } from "react";
import MainLayout from "../../../../layouts/mainLayout.js";
import { Form, Input, Button, Select, message, Card, Tag, Space, Input as TextArea } from "antd";
import {
    ArrowLeftOutlined,
    SaveOutlined,
    WarningOutlined,
    AlertOutlined,
    MedicineBoxOutlined,
    LinkOutlined,
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

const { Option } = Select;

const SkinCareIngredientsEdit = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [ingredients, setIngredients] = useState([]);
    const [healthEffects, setHealthEffects] = useState([]);
    const healthEffectOptions = [
        { label: 'Irritation', value: 'irritation', icon: <WarningOutlined /> },
        { label: 'Allergy', value: 'allergy', icon: <AlertOutlined /> },
        { label: 'Sensitivity', value: 'sensitivity', icon: <MedicineBoxOutlined /> },
        { label: 'Dryness', value: 'dryness', icon: <WarningOutlined /> },
        { label: 'Acne', value: 'acne', icon: <AlertOutlined /> },
    ];

    // Fetch product data
    useEffect(() => {
        const fetchIngredientData = async () => {
            try {
                const response = await fetch(`https://findbluapp.com/skincare_ingredients?id=${id}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        action: "view",
                        data: { id: id },
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                    const ingredientData = data.data[0];
                    console.log(ingredientData);

                    form.setFieldsValue({
                        title: ingredientData.title,
                        risk_level: ingredientData.risk_level.toLowerCase(),
                        health_effects: JSON.parse(ingredientData.health_effects),
                        details: ingredientData.details,
                        sources: JSON.parse(ingredientData.sources),
                    });

                    console.log(
                        {
                            title: ingredientData.title,
                            risk_level: ingredientData.risk_level.toLowerCase(),
                            health_effects: JSON.parse(ingredientData.health_effects),
                            details: ingredientData.details,
                            sources: JSON.parse(ingredientData.sources),
                        }
                    );
                    setIngredients(form);
                    setHealthEffects(JSON.parse(ingredientData.health_effects));
                }
            } catch (error) {
                message.error("Failed to fetch product data.");
            }
        };

        if (id) {
            fetchIngredientData();
        }
    }, [id, form]);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            console.log(values);

            const payload = {
                action: "edit",
                data: {
                    id: id.toString(), // Ensure id is a string
                    title: values.title,
                    riskLevel: values.risk_level,
                    healthEffects: Array.isArray(values.health_effects) ? values.health_effects : [], // Ensure healthEffects is an array
                    details: values.details,
                    sources: values.sources,
                },
            };

            const response = await fetch("https://findbluapp.com/skincare_ingredients", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                message.success("Product saved successfully!");
                navigate("/skincare/ingredients");
            } else {
                throw new Error("Failed to save product.");
            }
        } catch (error) {
            message.error("Error saving product.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <MainLayout>
            <div>
                <div className="mb-4 flex items-center gap-4">
                    <Button
                        icon={<ArrowLeftOutlined />}
                        onClick={() => navigate('/skincare/ingredients')}
                    >
                        Back
                    </Button>
                    <h1 className="text-2xl font-bold">New Ingredient</h1>
                </div>

                <Card className="max-w-2xl">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        disabled={loading}
                    >
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[{ required: true, message: 'Please input ingredient title!' }]}
                        >
                            <Input placeholder="Enter ingredient title" />
                        </Form.Item>

                        <Form.Item
                            name="risk_level"
                            label="Risk Level"
                            rules={[{ required: true, message: 'Please select risk level!' }]}
                        >
                            <Select placeholder="Select risk level">
                                <Option value="high">
                                    <Tag color="red">High Risk</Tag>
                                </Option>
                                <Option value="medium">
                                    <Tag color="orange">Medium Risk</Tag>
                                </Option>
                                <Option value="low">
                                    <Tag color="green">Low Risk</Tag>
                                </Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="health_effects"
                            label="Health Effects"
                            rules={[{ required: true, message: 'Please select health effects!' }]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select health effects"
                                style={{ width: '100%' }}
                            >
                                {healthEffectOptions.map(option => (
                                    <Option key={option.value} value={option.value}>
                                        <Space>
                                            {option.icon}
                                            {option.label}
                                        </Space>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>


                        <Form.Item
                            name="details"
                            label="Details"
                            rules={[{ required: true, message: 'Please input details!' }]}
                        >
                            <TextArea
                                rows={4}
                                placeholder="Enter detailed description of the ingredient"
                            />
                        </Form.Item>

                        <Form.List
                            name="sources"
                            initialValue={['']}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            key={field.key}
                                            style={{ marginBottom: 8 }}
                                        >
                                            <Form.Item
                                                {...field}
                                                label={index === 0 ? "Sources" : ""}
                                                required={true}
                                                rules={[
                                                    { required: true, message: 'Please input source URL' },
                                                    { type: 'url', message: 'Please enter a valid URL' }
                                                ]}
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Input
                                                    placeholder="Enter source URL"
                                                    prefix={<LinkOutlined />}
                                                    suffix={
                                                        fields.length > 1 && (
                                                            <MinusCircleOutlined
                                                                onClick={() => remove(field.name)}
                                                                className="text-red-500 cursor-pointer"
                                                            />
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            icon={<PlusOutlined />}
                                            className="w-full"
                                        >
                                            Add Source
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                icon={<SaveOutlined />}
                                loading={loading}
                                className="bg-blue-600 hover:bg-blue-700"
                            >
                                Save Ingredient
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </MainLayout>
    );
};

export default SkinCareIngredientsEdit;
