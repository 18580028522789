import React from 'react';
import { Link, useRouteError } from 'react-router-dom';
import './error-page.css';

const ErrorPage = () => {
    const error = useRouteError();

    return (
        <div className="error-container">
            <h1>404</h1>
            <h2>Sayfa Bulunamadı</h2>
            <p>
                {error?.statusText || error?.message ||
                    "Aradığınız sayfa mevcut değil veya taşınmış olabilir."}
            </p>
            <Link to="/" className="home-button">
                Ana Sayfaya Dön
            </Link>
        </div>
    );
};

export default ErrorPage;