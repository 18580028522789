import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MainLayout from '../../layouts/mainLayout';
import { Table, Button, Modal, message, Upload, Popconfirm, Input, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import { IoIosSearch } from 'react-icons/io';

const ImageUploadCategoryContainer = () => {
    const [data, setData] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false); // New state for loader
    const [selectedRows, setSelectedRows] = useState([]); // Add this new state

    const location = useLocation();
    const currentPathName = location.pathname;
    const segments = currentPathName.split('/').filter(segment => segment !== '');
    const catId = segments[3];

    const fetchData = async () => {
        try {
            const response = await fetch('https://findbluapp.com/web/imageupload', {
                method: 'POST',
                headers: {
                    Authorization: process.env.REACT_APP_DRAW_CATEGORIES_KEY,
                },
                body: JSON.stringify({
                    action: 'categoriesElements',
                    categoryId: catId,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const fetchedData = await response.json();
            fetchedData.sort((a, b) => b.imgId - a.imgId);
            setData(fetchedData);
        } catch (error) {
            console.error('Error fetching data:', error);
            message.error('Data could not be fetched.');
        }
    };

    useEffect(() => {
        fetchData();
    }, [catId]);

    const handleDelete = async (imgId) => {
        try {
            const response = await fetch('https://findbluapp.com/web/imageupload', {
                method: 'POST',
                headers: {
                    Authorization: process.env.REACT_APP_DRAW_CATEGORIES_KEY,
                },
                body: JSON.stringify({
                    action: 'deleteImage',
                    imgId: imgId,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete image');
            }

            message.success('Image deleted successfully!');
            fetchData(); // Refresh the data
        } catch (error) {
            console.error('Error deleting image:', error);
            message.error('Failed to delete image.');
        }
    };

    const handleUploadChange = ({ fileList }) => {
        setFileList(fileList.filter(file => !!file.status));
    };

    const handleUpload = async () => {
        if (!fileList.length) {
            message.error('Lütfen dosya seçin!');
            return;
        }

        setLoading(true); // Show loader

        try {
            const promises = fileList.map(async file => {
                const formData = new FormData();
                formData.append('imageUpload', file.originFileObj);
                formData.append('categoryId', catId);
                formData.append('action', "insertimage");

                const response = await fetch('https://findbluapp.com/web/imageupload', {
                    method: 'POST',
                    headers: {
                        'Authorization': process.env.REACT_APP_DRAW_CATEGORIES_KEY,
                    },
                    body: formData
                });

                if (!response.ok) {
                    throw new Error(`Görsel yüklemesi başarısız! (${file.name})`);
                }

                return await response.json();
            });

            const results = await Promise.all(promises);

            const successfulFiles = results.filter(result => result.status === 'success').map(result => result.uploadedFiles[0]);
            const failedFiles = results.filter(result => result.status === 'error');

            if (successfulFiles.length) {
                message.success(`${successfulFiles.length} görsel başarıyla yüklendi!`);
            }
            if (failedFiles.length) {
                message.error(`${failedFiles.length} görsel yüklenemedi!`);
            }

            setFileList([]);
            setIsUploadModalVisible(false);
            fetchData();

        } catch (error) {
            message.error('Görsel yükleme sırasında hata oluştu!');
            console.error('Görsel yükleme hatası:', error);
        } finally {
            setLoading(false); // Hide loader
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };
    
    const filteredData = data.filter((img) =>
        img.imgId.toString().includes(searchTerm) // Search by imgId instead of imgName
    );
    
    

    const columns = [
        {
            title: 'Image ID', // New column for imgId
            dataIndex: 'imgId',
            key: 'imgId',
        },
        {
            title: 'Image',
            dataIndex: 'imgPath',
            key: 'imgPath',
            render: (imgPath) => <img src={imgPath} alt="preview" width="50" />,
        },
        {
            title: 'Name',
            dataIndex: 'imgName',
            key: 'imgName',
        },
        {
            title: 'Size (MB)',
            dataIndex: 'imgSize',
            key: 'imgSize',
            render: (imgSize) => (imgSize / 1024 / 1024).toFixed(2),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Popconfirm
                    title="Are you sure you want to delete this image?"
                    onConfirm={() => handleDelete(record.imgId)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button danger>Delete</Button>
                </Popconfirm>
            ),
        },
    ];

    const handleBulkDelete = async () => {
        setLoading(true);
        try {
            const promises = selectedRows.map(imgId =>
                fetch('https://findbluapp.com/web/imageupload', {
                    method: 'POST',
                    headers: {
                        Authorization: process.env.REACT_APP_DRAW_CATEGORIES_KEY,
                    },
                    body: JSON.stringify({
                        action: 'deleteImage',
                        imgId: imgId,
                    }),
                })
            );

            await Promise.all(promises);
            message.success(`${selectedRows.length} images deleted successfully!`);
            setSelectedRows([]); // Clear selection
            fetchData(); // Refresh the data
        } catch (error) {
            console.error('Error deleting images:', error);
            message.error('Failed to delete images.');
        } finally {
            setLoading(false);
        }
    };

    const rowSelection = {
        selectedRowKeys: selectedRows,
        onChange: (selectedRowKeys) => {
            setSelectedRows(selectedRowKeys);
        },
    };

    return (
        <MainLayout>
            <div>
                {loading && (
                    <div
                        style={{
                            position: 'absolute',
                            inset: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 9999,
                        }}
                    >
                        <Spin size="large" />
                    </div>
                )}
                <div className={styles.appsHeading}>
                    <div className={styles.searchDiv}>
                        <IoIosSearch />
                        <Input
                            type="search"
                            placeholder="Search"
                            onChange={handleSearch}
                        />
                    </div>
                    <div>
                        {selectedRows.length > 0 && (
                            <Popconfirm
                                title={`Are you sure you want to delete ${selectedRows.length} images?`}
                                onConfirm={handleBulkDelete}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger style={{ marginRight: '10px' }}>
                                    Delete Selected ({selectedRows.length})
                                </Button>
                            </Popconfirm>
                        )}
                        <Button onClick={() => setIsUploadModalVisible(true)} type="primary">
                            <UploadOutlined /> Upload New Image
                        </Button>
                        <Modal
                            visible={isUploadModalVisible}
                            title="Upload Image"
                            onCancel={() => setIsUploadModalVisible(false)}
                            onOk={handleUpload}
                            okText="Upload" // Set OK button text to "Upload"
                            okButtonProps={{
                                disabled: loading, // Disable the button while loading
                            }}
                        >
                            <Upload
                                fileList={fileList}
                                onChange={handleUploadChange}
                                multiple
                            >
                                <Button icon={<UploadOutlined />}>Select Files</Button>
                            </Upload>
                        </Modal>
                    </div>
                </div>
                <Table 
                    dataSource={filteredData} 
                    columns={columns} 
                    rowKey="imgId"
                    rowSelection={rowSelection}
                />
            </div>
        </MainLayout>
    );
};

export default ImageUploadCategoryContainer;
